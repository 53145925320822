import logo from './image.jpeg';
import React from "react";
import './App.css';

function App() {
  return (
    <React.Fragment>
    <div className="row main">
      <div className="col-7 mainText">
        <h2 className='h2title'> PROF. S. C. Chaturvedi</h2>
        <h5 className='h6subtitle'>Director, Sri Aurobindo Institute of Pharmacy, Indore</h5>
        <h5 className='insideText'>Acclaimed globally for his contribution in molecular modeling and drug design research, Sir has contributed fifty years of his dedicated service towards Pharmaceutical Education and Research. Sir was born on 3rd March, 1948. He received his Ph. D. in pharmaceutical Sciences from University of Sagar (MP), India in 1980. 116 National,  50 International,  05 Book Chapters are in his account. He has supervised  36 PhDs and around 150 post graduate projects.
        </h5>
        <br/>
        <h5 className='h6subtitle'>Professional Journey</h5>
        <h5 className='insideText'>
          <ul>
            <li><b>1973 -1981:</b> Lecturer, <b>Holkar Science College, Indore,</b> India.</li>
            <li><b>1981-1985:</b> Lecturer, <b>University of Nigeria,</b> Nsukka, Nigeria</li>
            <li><b>1987-1988:</b> Reader & Head, <b>State college of Pharmacy,</b> Indore, India</li>
            <li><b>1988-1992:</b> Professor & Head, <b>State college of Pharmacy,</b> Indore</li>
            <li><b>1992-March 2003:</b> Professor & Head, <b>Dept. of Pharmacy, SGSITS,</b> Indore</li>
            <li><b>1988 -1996:</b> Dean, <b>Faculty of Technology, DAVV,</b> Indore, India</li>
            <li><b>1999 -2001:</b> Dean, Academics, <b>SGSITS,</b> Indore.</li>
            <li><b>1999 -2001:</b> Dean, <b>Faculty of Technology, DAVV,</b> Indore.</li>
            <li><b>2001-2003:</b> Dean, <b>Faculty of Pharmacy, RGPV,</b> Bhopal.</li>
            <li><b>March 2003-August 2003:</b> Rector (Pro Vice Chancellor)<b>,RGPV,</b> Bhopal.</li>
            <li><b>August 2003-December 2003:</b> Vice Chancellor, <b>RGPV,</b> Bhopal.</li>
            <li><b>2004-2006:</b> Professor & Head, <b>School of Pharmacy, DAVV,</b> Indore.</li>
            <li><b>2009-Current:</b> Director of <b>Sri Aurobindo Institute of Pharmacy,</b> Indore.</li>
          </ul>
        </h5>
        <br/><br/>
        <h5 className='h6subtitle'>Professional bodies positions held and memberships</h5>
        <h5 className='insideText'>
        <ul>
          <li><b>2008-2014:</b> President/Vice President, <b>Indian Pharmaceutical Association (IPA) M.P Branch</b></li>
          <li><b>1990-1992:</b> Vice President, <b>Association of Pharmacy Teachers of India (APTI)</b></li>
          <li>Life member: <b>Indian Pharmaceutical Association (IPA)</b></li>  
          <li>Life member: <b>Association of Pharmacy Teachers of India (APTI)</b></li>  
          <li>Life Member: <b>Indian Society of Technical Education (ISTE), New Delhi</b></li>  
          <li>Expert in various bodies of <b>central and state governments</b> i.e. <b>UPSC, New Delhi, PCS, Madhya Pradesh and Uttar Pradesh</b></li>
          <li>Expert association with <b>Council of Scientific and Industrial Research (CSIR), New Delhi</b></li>
          <li>Expert association with <b>All India Council of Technical Education (APTI), New Delhi</b></li>
          <li>Expert  association  with <b>Pharmacy Council of India, New Delhi</b></li>
        </ul>
        </h5>
        <br/><br/>
        <h5 className='h6subtitle'>Awards</h5>
        <h5 className='insideText'>
          <ul>
          <li>Year 2000: <span>Prof. M.L. Khurana Memorial best paper award</span> in the subject of medicinal chemistry, by <span>Indian Pharmaceutical Association (IPA), Mumbai, Maharashtra</span></li>
          <li>Year 2005: Indian drugs manufacturer Association <span>(IDMA) Best research paper award</span></li>
          </ul>
        </h5>
        <br/><br/>
        <h5 className='h6subtitle'>Felicitations</h5>
        <h5 className='insideText'>
          <ul>
          <li>2019: For his <span>remarkable contribution as a Vice Chancellor</span> in the overall development of university, on the occasion of <b>21st Establishment year of Rajiv Gandhi Technical University, Bhopal (MP)</b></li>
          <li>2016: Life Time Achievement Award for being an illustrious alumnus <b>Department of Pharmaceutical Sciences, Dr. H. S. Gour Vishwavidyalaya, Sagar (MP)</b></li>
          <li>2013: Certificate of <span>appreciation for valuable research contribution by American Chemical Society, USA</span></li>  
          </ul>
        </h5>
      </div>
      <div className='col-2 imageContainer'>
      <div className="App">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      </div>
      <div className="col-3 right">
      
      </div>
    </div>
    
    </React.Fragment>
  );
}

export default App;
